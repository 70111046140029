//
// map.js
// Theme module
//

const maps = document.querySelectorAll("[data-map]");
const accessToken =
  "pk.eyJ1IjoidmluY2UtY2FzYXMiLCJhIjoiY2twMGF6OHJ1MTFwbTJucWo3bmM1NG56MCJ9.XtabmKLw9hYGUNSlEqTEUQ";

maps.forEach(map => {
  const elementOptions = map.dataset.map;

  const defaultOptions = {
    container: map,
    style: "mapbox://styles/mapbox/light-v10",
    scrollZoom: false,
    interactive: false,
    center: [-97.73333, 30.266666],
    zoom: 11
  };

  const options = {
    ...defaultOptions,
    ...elementOptions
  };

  // Get access token
  mapboxgl.accessToken = accessToken;

  // Init map
  new mapboxgl.Map(options);
});
